import React from "react";
import {
  WebsiteSchema,
  SiteNavigationElementSchema,
  FoodEstablishmentSchema,
  Navigation,
  RecaptchaProvider,
  Footer,
  SiteMap,
  CopyrightLabel,
  OutboundLink,
  FishermanBanner,
} from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql, StaticQuery } from "gatsby";

import { getPageHeroImage } from "../utils/utils";
import "semantic-ui-less/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        fishermanBusiness {
          name
          type
          subtypes
        }
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            title
            pageType
            url
            navigationPriority
            groupName
            props
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(title: { eq: "Home" }) {
          components {
            fastId
            order
            files {
              file
              gatsbyFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        fishermanBusiness,
        allFishermanBusinessWebsitePage,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
      } = data;

      return (
        <React.Fragment>
          <WebsiteSchema
            name={fishermanBusiness.name}
            url={"https://www.attorneyjohndavis.com"}
          />
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.attorneyjohndavis.com"}
          />
          <FoodEstablishmentSchema
            name={fishermanBusiness.name}
            siteUrl={"https://www.attorneyjohndavis.com"}
            businessType={fishermanBusiness.type}
            businessSubtypes={fishermanBusiness.subtypes}
            address={allFishermanBusinessLocation.nodes}
            images={getPageHeroImage({
              options: fishermanBusinessWebsitePage.components,
            })}
          />
          <div className={"site-container"}>
            <div>
              <Navigation
                links={allFishermanBusinessWebsitePage.nodes}
                bordered={false}
                centerMobileNavItems={true}
                fixed={false}
                header={fishermanBusiness.name}
                linksAs={"text"}
                primaryContentCentered={true}
                primaryContentPosition={"top"}
                evenSpacing={true}
              />
            </div>
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
              <Footer
                backgroundColor={"primary"}
                horizontalAlign={"center"}
                verticalAlign={"middle"}
                columns={[
                  <div />,
                  <div>
                    <SiteMap
                      links={allFishermanBusinessWebsitePage.nodes}
                      horizontal={true}
                      bulleted={false}
                    />
                    <CopyrightLabel
                      company={fishermanBusiness.name}
                      phrase={"All Rights Reserved"}
                      privacyPolicyLink={
                        <OutboundLink
                          to={
                            "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                          }
                          label={"Privacy Policy"}
                          eventLabel={"copyright-privacy-policy"}
                        />
                      }
                    />
                  </div>,
                  <div />,
                ]}
              />
            </RecaptchaProvider>
            <div className={"footer-image-container"}>
              <Grid
                stackable={true}
                className={""}
                verticalAlign={"middle"}
                textAlign={"center"}
              >
                <Grid.Column width={5} textAlign={"center"}>
                  <Grid
                    columns={"equal"}
                    stackable={true}
                    verticalAlign={"middle"}
                  >
                    <Grid.Column>
                      <Image
                        as={"a"}
                        target={"_blank"}
                        href={
                          "https://www.bbb.org/us/in/merrillville/profile/lawyers/john-h-davis-attorney-at-law-0352-90064100/#sealclick"
                        }
                        size={"small"}
                        centered={true}
                        src={
                          "https://seal-fortwayne.bbb.org/seals/blue-seal-250-52-bbb-90064100.png"
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image
                        size={"tiny"}
                        centered={true}
                        src={
                          "https://fisherman.gumlet.io/public/attorneyjohndavis/TA_BADGE_NO_BACKGROUND.png"
                        }
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </div>
          </div>
          <FishermanBanner />
        </React.Fragment>
      );
    }}
  />
);
